<template>
    <div>
        <p class="features">
            <span v-if="formatYear(item.attributes.found_year)" title="学校成立时长"><cite class="year"></cite>{{
                formatYear(item.attributes.found_year) }}</span>
            <span title="学校规模"><cite class="scale"></cite>{{ formatScale(item.attributes.scale) }}</span>
            <span title="学生口碑"><cite class="level"></cite>{{ formatLevel(item.attributes.score) }}</span>
            <span title="学校评分"><cite class="score"></cite>{{ formatScore(item.attributes.score) }}</span>
        </p>
    </div>
</template>

<script setup>
const props = defineProps({
    item: Object
})
const item = ref(props.item);


const formatYear = (str)=>{
    let year = null;
    if(!str){
        return null;
    }
    const currentYear = new Date().getFullYear();
    const years = currentYear - (+str);
    if(years > 30){
        year = "30年+"
    }else if(years > 20){
        year = "20年+"
    }else if(years > 10){
        year = "10年+"
    }else if(year >5){
        year = "5年+"
    }
    return year;
}
const formatScore =(str)=>{
    let score = "4";
    // if(!str){
    //     return null;
    // }
    if(str){
        score = str;
    }
    return score;
}
const formatLevel = (str)=>{

// 不错 小于 4.0(含)以下
// 好  4.1 -4.6
// 很好 4.7 4.8 4.9
    let level = "不错";
    if(+str >=4.7){
        level = "很好";
    }else if(+str > 4){
        level = "好"   
    }
    return level;
}

const formatScale = (str)=>{
    let scale = "中规模";
    if(str == "X"){
        scale = "小规模"
    }
    if(str == "M"){
        scale = "中规模"
    }
    if(str=="XL"){
        scale = "庞大"
    }
    return scale;
}
</script>


<style scoped>
.features>span {
    display: inline-block;
    /* border: 1px solid #FFE117; */
    margin: 12px 12px 0 0;
    border-radius: 6px;
    line-height: 24px;
    font-size: 12px;
    background-color: #FFE117;
    padding: 2px 6px;
}

.features cite {
    background-size: 26px;
    width: 26px;
    height: 26px;
    padding-left: 20px;
    background-repeat: no-repeat;
    /* display: inline-block; */
    background-size: 20px;
    font-size: 14px;
    margin-right: 2px;
}

.year {
    background-image: url("/assets/img/school_icons_year.png");
}

.scale {
    background-image: url("/assets/img/school_icons_scale.png");
}

.level {
    background-image: url("/assets/img/school_icons_level.png");
}

.score {
    background-image: url("/assets/img/school_icons_score.png");
}
</style>
